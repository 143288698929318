* {
  box-sizing: border-box;
}
.EditorApp {
  width: 100%;
  height: 100%;
}
.EditorApp:has([data-wants-spinner-cursor=true]) {
  cursor: wait;
}
